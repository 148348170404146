import api from "../axiosInstance";
import {getUserRole, logout} from "../../utils/authUtils";
import axios from "axios";

const role = getUserRole();

async function allAnnonce(redirectTo = null) {

    try {
        const apiUrl = process.env.API_URL;
        let url = '/annonce/get';
        if (role === 'super-admin' || role === 'all_pays') {
            url += '';
        } else {
            console.log('je passe')
            url += `/${role}`;
        }
        console.log(url);
        console.log(role)
        const response = await api.get(url);
        console.log(response);
        return response.data; // Si vous attendez des données en réponse
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.log("Unauthorized access");
            console.log("Deconnexion: Unauthorized access");
            logout()
        }
        console.log(error);
        if (error.response && error.response.data && error.response.data.errors) {
            // Si des erreurs sont renvoyées par le serveur
            const { errors } = error.response.data;
            return { errors };
        } else {
            // Si une erreur inattendue s'est produite
            console.error('error:', error.message);
            throw error;
        }
    }
}

export { allAnnonce };
