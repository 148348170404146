import React, { useState } from 'react';
import {deleteUser} from "../../../Service/User/UserDelete";
const DeleteUserButton = ({ id }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState("");

    const handleDeleteClick = async () => {
        setIsDeleting(true);
        try {
            const response = await deleteUser(id);
            alert("User deleted successfully!"); // Consider using a more integrated notification system
        } catch (error) {
            console.error('Error deleting user:', error);
            setDeleteError("Error while deleting the user.");
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div>
            <button
                onClick={handleDeleteClick}
                disabled={isDeleting}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
            >
                {isDeleting ? "Deleting..." : "Delete User"}
            </button>
            {deleteError && <p className="text-red-500">{deleteError}</p>}
        </div>
    );
};

export default DeleteUserButton;
