import axios from 'axios';
import {logout} from "../../utils/authUtils";

async function allCountry() {
    try {
        const response = await axios.get('https://api.tddays.fr/api/allcountry');
        return response.data; // Si vous attendez des données en réponse
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Unauthorized access
            logout()
        }
        if (error.response && error.response.data && error.response.data.errors) {
            // Si des erreurs sont renvoyées par le serveur
            const { errors } = error.response.data;
            return { errors };
        } else {
            // Si une erreur inattendue s'est produite
            console.error('error:', error.message);
            throw error;
        }
    }
}

export { allCountry };
