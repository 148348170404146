import React from 'react';
import { useFormContext } from 'react-hook-form';

const Input = ({ type = 'text', label, name, options, defaultValue, ...props }) => {
    const { register, formState: { errors } } = useFormContext(); // Utilise le contexte du formulaire

    // Styles de base pour les inputs et labels
    const inputStyles = `shadow appearance-none border rounded w-full py-1 px-2 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
        errors[name] ? "border-red-500" : "border-gray-300"
    }`;
    const labelStyles = "block text-gray-700 text-sm font-bold mb-1";
    const errorStyles = "text-red-500 text-xs italic";

    let inputElement = null;
    switch (type) {
        case 'select':
            inputElement = (
                <select id={name} {...register(name)} {...props} className={inputStyles}>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            );
            break;
        case 'textarea':
            inputElement = (
                <textarea id={name} {...register(name)} className={inputStyles} defaultValue={defaultValue} rows="4" {...props} />
            );
            break;
        case 'submit':
            inputElement = (
                <button type={type} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" {...props}>
                    {label}
                </button>
            );
            break;
        default:
            inputElement = <input type={type} id={name} {...register(name)} className={inputStyles} defaultValue={defaultValue} {...props} />;
    }

    return (
        <div className="mb-2"> {/* Réduire l'espacement entre les éléments */}
            {label && type !== 'submit' && <label htmlFor={name} className={labelStyles}>{label}</label>}
            {inputElement}
            {errors[name] && <p className={errorStyles}>{errors[name].message}</p>} {/* Affiche l'erreur de validation */}
        </div>
    );
};

export default Input;
