import api from "../axiosInstance";
import {getUserRole, logout} from "../../utils/authUtils";

const role = getUserRole();

async function allCampagne(redirectTo = null) {
    try {
        let url = '/campagne/get';

        if (role === 'super-admin' || role === 'all_pays') {
            url += '';
        } else {
            url += `/${role}`;
        }

        const response = await api.get(url);


        return response.data;
    } catch (error) {

        if (error.response && error.response.status === 401) {
            // Unauthorized access
            logout()
        }

        if (error.response && error.response.data && error.response.data.errors) {
            // If server returns errors
            const { errors } = error.response.data;
            return { errors };
        } else {
            // If an unexpected error occurred
            console.error('error:', error.message);
            throw error;
        }
    }
}

export { allCampagne };
