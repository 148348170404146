import React, { useState, useEffect } from "react";
import Input from "../../Commons/Component/ui/Input";
import Background from "../../Commons/Component/ui/body-color-auth";
import { login } from "../../Service/Auth/loginService";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ApiError from "../../Commons/Error-succes/Api-Error";
import ApiSuccess from "../../Commons/Error-succes/Api-succes";
import loginSchema from "../../validation/loginSchema";
import {Link} from "react-router-dom";

export default function Login() {
    const methods = useForm({
        resolver: yupResolver(loginSchema),
    });

    const [loginError, setLoginError] = useState("");
    const [loginSuccess, setLoginSuccess] = useState("");

    const onSubmit = async (data) => {
        try {
            const response = await login(data);
            console.log("User logged in successfully:", response);
            if (response.errors) {
                setLoginError(response.errors[0].message); // Set the error message only
                setLoginSuccess("");
            } else {
                setLoginError("");
                setLoginSuccess(response.success);

                // Stocker le résultat de la connexion et le jeton JWT dans localStorage
                localStorage.setItem("userData", JSON.stringify(response));
                localStorage.setItem("token", response.value.token);
                console.log(localStorage.getItem("token"));
                console.log(localStorage.getItem("userData"));
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setLoginError("Invalid credentials"); // Set a generic error message
                console.error("Login error:", error);
            } else if (error.response && error.response.status === 403) {
                setLoginError(error.response.data.message); // Set a generic error message
                console.error("Login error:", error);
            }  else {
                console.error("Unexpected error:", error);
            }
        }
    };

    // Vérifiez si l'utilisateur est déjà connecté lors du chargement du composant
    useEffect(() => {
        const userData = localStorage.getItem("token");
        if (userData) {
            window.location.assign('/annonce'); // Redirection vers la page de connexion
        }
    });

    return (
        <Background>
            <div className="flex w-3/4 place-content-center justify-center items-center bg-white h-80vh">
                <div className="min-h-screen flex justify-center items-center">
                    <FormProvider {...methods}>
                        <form
                            onSubmit={methods.handleSubmit(onSubmit)}
                            style={{ maxWidth: "800px", width: "100%", padding: "40px" }}
                        >
                            {loginError && <ApiError error={loginError} />} {/* Display error message only */}
                            {loginSuccess && <ApiSuccess message={JSON.stringify(loginSuccess)} />}


                            <Input
                                type="email"
                                placeholder="Email"
                                color="blue"
                                size="lg"
                                label="Email"
                                name="email"
                            />
                            <Input
                                type="password"
                                placeholder="Password"
                                color="red"
                                size="lg"
                                label="Password"
                                name="password"
                            />

                            <div className="mb-6 flex justify-center">
                                <button
                                    type="submit"
                                    className="bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline cursor-pointer"
                                >
                                    Login
                                </button>
                                                           </div>
                            <Link to="/register" className="text-center underline underline-offset-1 flex justify-center">Register</Link>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </Background>
    );
}
