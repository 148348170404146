import React from 'react';
import './CustomCard.css'; // Assurez-vous de créer le fichier CustomCard.css pour définir les styles

const CustomCampagneView = ({ campagne }) => {
    return (
        <div className="custom-card-container">
            <div className="custom-card">
                <div className="card-content">
                    <h2 className="title">{campagne.client}</h2>
                    <p className="Sale">Sale : {campagne.sale.firstName + ' ' + campagne.sale.lastName}</p>
                    <img src={decodeURIComponent(campagne.image)} alt="Campagne" className="image" />
                    <div className="details">
                        <p><span className="label">Date de signature:</span> {campagne.signature ? new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(campagne.signature)) : '-'}</p>
                        <p><span className="label">Account Manager:</span> {campagne.accountManager ? (campagne.accountManager.firstName + ' ' + campagne.accountManager.lastName) : '-'}</p>
                        <p><span className="label">Account Manager Fils:</span> {campagne.accountManagerFils && campagne.accountManagerFils.firstName && campagne.accountManagerFils.lastName ? (campagne.accountManagerFils.firstName + ' ' + campagne.accountManagerFils.lastName) : '-'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomCampagneView;
