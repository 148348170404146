import React from "react";

function ApiSucces({ message }) {
    return (
        <div className="success-message">
            <p className="text-green-500">{message}</p>
        </div>
    );
}


export default ApiSucces;
