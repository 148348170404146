import React, { useEffect, useState } from 'react';
import CustomModal from "../../Commons/Component/CustomModal";
import Pagination from "../../Commons/Component/Pagination/paginationTableau";
import { allUser } from "../../Service/User/UserGet";
import DeleteUserButton from "../../Commons/Component/User/DeleteUser";
import ActivateUserButton from "../../Commons/Component/User/ActiveUser";
import CountryFilter from "../../Commons/Component/Filtre/CountryFilter";

const UserTable = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalAction, setModalAction] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(2); // Limite à 2 utilisateurs par page
    const [selectedCountry, setSelectedCountry] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await allUser();
                if (data.errors) {
                    setError(data.errors);
                } else {
                    setUsers(data);
                }
            } catch (err) {
                setError(err.message || 'An error occurred while fetching users.');
            }
        };
        fetchUsers();
    }, []);

    const openModal = (id, action) => {
        const user = users.find((u) => u.id === id);
        if (user) {
            setSelectedUser(user);
            setModalAction(action);
            setIsModalOpen(true);
        }
    };

    const openAddModal = () => {
        setIsModalOpen(true);
        setSelectedUser(null);
        setModalAction('add');
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    let filteredUsers = users;
    if (selectedCountry) {
        filteredUsers = filteredUsers.filter(user => user.countryName === selectedCountry);
    }

    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className="p-4">
                <h1 className="text-2xl font-bold mb-4">Users</h1>
                <CountryFilter
                    countries={[...new Set(users.map(user => user.countryName))]}
                    onCountryChange={setSelectedCountry}
                />
                <div className="overflow-x-auto">
                    <table className="table-auto w-full whitespace-nowrap text-left divide-y divide-gray-200">
                        <thead>
                        <tr>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">ID</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">First Name</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Last Name</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Email</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Image</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Role</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {currentUsers.map((user, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="px-4 py-2">{user.id || '-'}</td>
                                <td className="px-4 py-2">{user.firstName || '-'}</td>
                                <td className="px-4 py-2">{user.lastName || '-'}</td>
                                <td className="px-4 py-2">{user.email || '-'}</td>
                                <td className="px-4 py-2">{decodeURIComponent(user.image) || '-'}</td>
                                <td className="px-4 py-2">{user.role || '-'}</td>
                                <td className="px-4 py-2">
                                    <div className="flex gap-2 justify-center items-center">
                                        <button onClick={() => openModal(user.id, 'view')}
                                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                                            View
                                        </button>
                                        {user.activeAccount === 0 && (
                                            <button
                                                onClick={() => openModal(user.id, 'active_account')}
                                                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                            >
                                                Activate the Account
                                            </button>
                                        )}
                                        <button onClick={() => openModal(user.id, 'delete')}
                                                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {totalPages > 1 && (
                    <Pagination usersPerPage={usersPerPage} totalUsers={filteredUsers.length} paginate={paginate} />
                )}
            </div>
            <CustomModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={selectedUser ? `User: ${selectedUser.firstName} ${selectedUser.lastName}` : ''}
            >
                {modalAction === 'active_account' && selectedUser && (
                    <>
                        <ActivateUserButton id={selectedUser.id} />
                    </>
                )}
                {modalAction === 'delete' && selectedUser && <DeleteUserButton id={selectedUser.id} />}
            </CustomModal>
        </>
    );
}

export default UserTable;
