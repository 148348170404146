import axios from 'axios';

async function login(userData) {
    try {
        const response = await axios.post('https://api.tddays.fr/api/auth/login', userData);
        return response.data; // Si vous attendez des données en réponse
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            // Si des erreurs sont renvoyées par le serveur
            const { errors } = error.response.data;
            return { errors };
        } else {
            // Si une erreur inattendue s'est produite
            console.error('error:', error.message);
            throw error;
        }
    }
}

export { login };
