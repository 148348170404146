// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
    position: relative;
    width: 300px;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.custom-card:hover {
    transform: translateY(-10px);
}

.card-content {
    padding: 20px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.description {
    margin-bottom: 20px;
}

.image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
}

.details {
    font-size: 14px;
}

.label {
    font-weight: bold;
    margin-right: 5px;
}

.custom-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Commons/Component/Cards/CustomCard.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,wCAAwC;IACxC,+BAA+B;AACnC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".custom-card {\n    position: relative;\n    width: 300px;\n    height: 400px;\n    overflow: hidden;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s ease;\n}\n\n.custom-card:hover {\n    transform: translateY(-10px);\n}\n\n.card-content {\n    padding: 20px;\n}\n\n.title {\n    font-size: 20px;\n    font-weight: bold;\n    margin-bottom: 10px;\n}\n\n.description {\n    margin-bottom: 20px;\n}\n\n.image {\n    width: 100%;\n    height: 200px;\n    object-fit: cover;\n    border-radius: 5px;\n    margin-bottom: 10px;\n}\n\n.details {\n    font-size: 14px;\n}\n\n.label {\n    font-weight: bold;\n    margin-right: 5px;\n}\n\n.custom-card-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
