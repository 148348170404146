import React, { useState } from 'react';
import { deleteAnnonce } from "../../../Service/Annonce/AnnonceDelete";

const DeleteAnnonceButton = ({ id }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState("");

    const handleDeleteClick = async () => {
        setIsDeleting(true);
        try {
            // Appel à la fonction deleteAnnonce pour supprimer l'annonce réelle
            await deleteAnnonce(id);
            alert("Annonce supprimée avec succès!"); // Remplacez par votre composant de notification

            // Recharger la page après la suppression réussie
            window.location.reload();
        } catch (error) {
            console.error('Error deleting annonce:', error);
            setDeleteError("Erreur lors de la suppression de l'annonce.");
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div>
            <button
                onClick={handleDeleteClick}
                disabled={isDeleting}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
            >
                {isDeleting ? "Suppress..." : "Delete Annonce"}
            </button>
            {deleteError && <p className="text-red-500">{deleteError}</p>}
        </div>
    );
};

export default DeleteAnnonceButton;
