import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../ui/Input';
import { editAnnonce } from "../../../Service/Annonce/AnnonceEdit";
import ApiSuccess from "../../Error-succes/Api-succes";
import { getCountryId } from "../../../utils/authUtils"; // Import the getCountryId function

// Validation schema using yup
const validationSchema = yup.object({
    presentation: yup.string().min(10, 'The presentation must contain at least 10 characters').required('The presentation is required'),
    image: yup.string().url('Must be a valid URL').required('The image URL is required'),
    date: yup.date().required('The date is required'),
    date_end: yup.date().nullable().optional(),
    categorie: yup.string().required('The category is required'),
    title: yup.string().min(2, 'The title must contain at least 2 characters').required('The title is required'),
    id_employe: yup.number().positive('The employee ID must be a positive number').required('The employee ID is required'),
    live: yup.boolean(),
    country_id: yup.number().positive('The country ID must be a positive number').required('The country ID is required'),
    status: yup.number().positive('The status must be a positive number').required('The status is required'),
});

const EditAnnonce = ({ annonce, dataErrors }) => {
    const [editAnnonceErrors, setEditAnnonceErrors] = useState([]);
    const [registrationSuccess, setRegistrationSuccess] = useState("");

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: annonce // Use the annonce for default values
    });
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = methods;

    const handleSaveClick = async () => {
        try {
            const data = getValues(); // Get the form data

            // Récupérer le country_id depuis localStorage
            let country_id = getCountryId();
            if (country_id) {
                country_id = Number(country_id);
                if (isNaN(country_id)) {
                    console.error("Invalid country_id:", country_id);
                    return; // Arrêter l'exécution si country_id est invalide
                }
                data.country_id = country_id;
            } else {
                console.error("No country_id found in localStorage");
                return; // Arrêter l'exécution si aucun country_id n'est trouvé
            }

            const response = await editAnnonce(data, annonce.id);
            if (response && response.errors) {
                setEditAnnonceErrors(response.errors);
            } else if (response && !response.errors) {
                setRegistrationSuccess("Annonce successfully modified!");
                setEditAnnonceErrors([]);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error editing annonce:', error);
        }
    };

    useEffect(() => {
        if (annonce) {
            Object.entries(annonce).forEach(([key, value]) => {
                setValue(key, value);
            });
        }

        // Set the country_id from localStorage
        const country_id = getCountryId();
        if (country_id) {
            setValue("country_id", country_id);
        }

        if (annonce && annonce.image) {
            setValue("image", decodeURIComponent(annonce.image)); // Decode the image URL and update in the form
        }
    }, [annonce, setValue]);

    const annonceState = [
        { value: "1", label: "Draft" },
        { value: "0", label: "Published" },
    ];

    const categorie = [
        { value: "general", label: "General" },
        { value: "social", label: "Social" },
        { value: "media", label: "Media" },
        { value: "departure", label: "Departure" },
    ];

    return (
        <div>
            <FormProvider {...methods}>
                <form>
                    {registrationSuccess && <ApiSuccess message={registrationSuccess} />}
                    {editAnnonceErrors.map((error, index) => (
                        <div className={"text-red-500"} key={index}>{error.message}</div>
                    ))}
                    {dataErrors && dataErrors.length > 0 && (
                        <div className="error-container">
                            {dataErrors.map((error, index) => (
                                <p key={index} className="error-message">{error.message}</p>
                            ))}
                        </div>
                    )}
                    <Input
                        type="textarea"
                        {...register("presentation")}
                        placeholder="Presentation"
                        label="Presentation"
                    />
                    <p>{errors.presentation?.message}</p>

                    <Input {...register("image")} placeholder="URL de l'image" color="blue" size="lg" label="Image" />
                    <p>{errors.image?.message}</p>

                    <Input {...register("date")} type="date" color="blue" size="lg" label="Date" />
                    <p>{errors.date?.message}</p>

                    <Input {...register("date_end")} type="date" color="blue" size="lg" label="End Date" />
                    <p>{errors.date_end?.message}</p>

                    <Input {...register("categorie")} type="select" options={categorie} color="blue" size="lg" label="Category" defaultValue="general" />
                    <p>{errors.categorie?.message}</p>

                    <Input {...register("title")} placeholder="Title" color="blue" size="lg" label="Title" />
                    <p>{errors.title?.message}</p>

                    <Input
                        {...register("live")}
                        type="select"
                        options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" }
                        ]}
                        color="blue"
                        size="lg"
                        label="Live"
                    />

                    {/* No need for a country_id select input as it's now directly from localStorage */}
                    <p>{errors.country_id?.message}</p>

                    <button type="button" onClick={handleSaveClick} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"> Save changes </button>
                </form>
            </FormProvider>
        </div>
    );
}

export default EditAnnonce;
