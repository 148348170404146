import React from 'react';
import isRole, { isLoggedIn } from "../utils/authUtils";

const ProtectedRoute = ({ children, allowedRoles }) => {
    const isAuthenticated = isLoggedIn();

    if (!isAuthenticated) {
        // Si l'utilisateur n'est pas connecté, redirigez-le vers la page de connexion
        window.location.assign('/login'); // Redirection vers la page de connexion
    }

    if (allowedRoles && allowedRoles.length > 0) {
        const hasRequiredRole = isRole(allowedRoles);
        console.log('ça passe');

        if (!hasRequiredRole) {
            console.log('unauthorized')
            // Si des rôles sont requis mais que l'utilisateur n'a pas le bon rôle, redirigez ou affichez un message
            window.location.assign('/login'); // Redirection vers la page de connexion
        }
    }

    // Si tout est ok, affichez l'enfant (la route protégée)
    return children;
};

export default ProtectedRoute;
