import React from 'react';
import { getUserRole } from "../../../utils/authUtils";

const CountryFilter = ({ countries, selectedCountry, setSelectedCountry }) => {
    const role = getUserRole();

    if (role !== 'super-admin' && role !== 'all_pays') {
        return null; // Cache le filtre si l'utilisateur n'a pas le rôle "super-admin" ou "all_pays"
    }

    const handleCountryChange = (e) => {
        const countryValue = e.target.value;
        setSelectedCountry(countryValue);
    };

    return (
        <select value={selectedCountry} onChange={handleCountryChange}>
            <option value="">All Countries </option>
            {countries.map((country) => (
                <option key={country} value={country}>
                    {country}
                </option>
            ))}
        </select>
    );
};

export default CountryFilter;
