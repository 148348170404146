import {getUserRole, logout} from "../../utils/authUtils";
import api from "../axiosInstance";

async function deleteUser(id) {
    try {
        const role = getUserRole(); // Déplacer ici si vous avez besoin de la valeur actuelle de role
        let url = '';
        if (role === 'super-admin' || role === 'all_pays') {
            url = `users/delete/${id}`;
        } else {
            url = `users/delete/${role}/${id}`;
        }

        const response = await api.delete(url);
        // Si vous avez besoin de faire quelque chose avec la réponse, faites-le ici
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Unauthorized access
            console.log("Unauthorized access");
            console.log("Deconnexion: Unauthorized access");
            logout()
        }
        console.error(error);
        throw error; // Selon le cas d'utilisation, vous pourriez vouloir gérer l'erreur différemment ici
    }
}

export { deleteUser };
