import React, { useState } from 'react';
import {acitveAccount} from "../../../Service/User/UserActive";

const ActivateUserButton = ({ id }) => {
    const [isActivating, setIsActivating] = useState(false);
    const [activateError, setActivateError] = useState("");

    const handleActivateClick = async () => {
        setIsActivating(true);
        try {
            const response = await acitveAccount(id);  // This should be your API call to activate the user
            alert("User activated successfully!");  // Consider using a more integrated notification system
        } catch (error) {
            console.error('Error activating user:', error);
            setActivateError("Error while activating the user.");
        } finally {
            setIsActivating(false);
        }
    };

    return (
        <div>
            <button
                onClick={handleActivateClick}
                disabled={isActivating}
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
            >
                {isActivating ? "Activating..." : "Activate User"}
            </button>
            {activateError && <p className="text-red-500">{activateError}</p>}
        </div>
    );
};

export default ActivateUserButton;
