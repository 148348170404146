import React from 'react';
import {getUserRole} from "../../../utils/authUtils";

const CountryFilter = ({ countries, onCountryChange }) => {
    const role = getUserRole();

    if (role !== 'super-admin' && role !== 'all_pays') {
        return null; // Cache le filtre si l'utilisateur n'a pas le rôle "super-admin" ou "all_pays"
    }

    return (
        <select onChange={(e) => onCountryChange(e.target.value)}>
            <option value="">All countries</option>
            {countries.map((country) => (
                <option key={country} value={country}>
                    {country}
                </option>
            ))}
        </select>
    );
};

export default CountryFilter;
