import React from "react";

function ApiError({ error }) {
    console.log(error)
    // Vérifier si l'objet error existe
    if (error) {
        return (
            <div className="api-error">
                <p className="text-red-500">{error}</p>
            </div>
        );
    } else {
        // Si l'objet error est vide, afficher un message par défaut
        return (
            <div className="api-error">
                <p>Une erreur est survenue.</p>
            </div>
        );
    }
}

export default ApiError;
