import React from 'react';
import './App.css';
import AppRoutes from "./AppRoutes";
import ProgressBar from "./Commons/Component/CampagneSlider"; // Importez AppRoutes à partir de votre fichier AppRoutes

function App() {
    return (
        <div className="App">
            <AppRoutes /> {/* Remplacez CampagnePage par AppRoutes */}
        </div>
    );
}

export default App;
