import React, { useState } from 'react';
import {deleteAnnonce} from "../../../Service/Annonce/AnnonceDelete";
import {deleteCampagne} from "../../../Service/Campagne/CampagneDelete";

// Assurez-vous d'importer tous les hooks et composants nécessaires ici

const DeleteCampagneButton = ({ id }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState("");

    // Déplacez la logique de suppression dans cette fonction
    const handleDeleteClick = async () => {
        setIsDeleting(true);
        try {
            // Appel API pour supprimer la campagne basée sur l'ID
            const response = await deleteCampagne(id);
            alert("Delete success!"); // Utilisez votre composant de notification ici
    
            // Recharger la page après un court délai pour que l'utilisateur voie le message de succès
            setTimeout(() => {
                window.location.reload();
            }, 100); // 1500 ms (1.5 secondes) avant le rechargement
        } catch (error) {
            console.error('Error deleting:', error);
            setDeleteError("Error.");
        } finally {
            setIsDeleting(false);
        }
    };
    

    return (
        <div>
            <button
                onClick={handleDeleteClick}
                disabled={isDeleting}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
            >
                {isDeleting ? "Suppress..." : "Delete"}
            </button>
            {deleteError && <p className="text-red-500">{deleteError}</p>}
        </div>
    );
};

export default DeleteCampagneButton;
