import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./page/connexion/login";
import Register from "./page/inscription";
import ProtectedRoute from "./routes/ProtectedRoute";
import Sidebar from "./sidebar_navigation/sidebar";
import CampagnePage from "./page/Campagne/CampagnePage";
import React from "react";
import AnnoncePage from "./page/Annonce/AnnoncePage";
import UserPage from "./page/User/UserPage";

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/campagne"   allowedRoles={['admin','user','super-admin','all_pays']} element={
                    <ProtectedRoute >
                        <Sidebar>
                            <CampagnePage />
                        </Sidebar>
                    </ProtectedRoute>
                } />

                <Route path="/annonce" element={
                    <ProtectedRoute allowedRoles={['admin','user','super-admin','all_pays']}>
                        <Sidebar>
                            <AnnoncePage />
                        </Sidebar>
                    </ProtectedRoute>
                } />

                <Route path="/user" element={
                    <ProtectedRoute allowedRoles={['admin','super-admin','all_pays']}>
                        <Sidebar>
                            <UserPage />
                        </Sidebar>
                    </ProtectedRoute>
                } />

                <Route path="/login" element={
                        <Login />
                } />

                <Route path="/register" element={
                    <Register />
                } />

                <Route path="/" element={
                    <Login />
                } />

                {/* Autres routes ici */}
            </Routes>
        </Router>
    );
};

export default AppRoutes;
