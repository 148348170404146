import React from 'react';
import Modal from 'react-modal';
import { XIcon } from '@heroicons/react/solid';

const CustomModal = ({ isOpen, onClose, title, children }) => {
    const customStyles = {
        content: {
            position: 'absolute',
            inset: '50% auto auto 50%',
            border: '1px solid rgb(204, 204, 204)',
            background: 'rgb(255, 255, 255)',
            overflow: 'auto', // Ajout de l'overflow pour la barre de défilement
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '600px', // Taille maximale de 600 pixels
            animation: 'popup 0.5s ease',
            height : '100vh'
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(77, 77, 77, 0.75)',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel={title}
            style={customStyles}
            overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        >
            <div className="flex flex-col items-center justify-center">
                <div className="absolute top-0 right-0 m-4">
                    <button onClick={onClose}>
                        <XIcon className="h-6 w-6 text-gray-500 hover:text-gray-700" />
                    </button>
                </div>

                <div className="bg-white rounded-lg shadow overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    {title}
                                </h3>
                                <div className="mt-2">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CustomModal;
