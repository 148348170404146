// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}
img {
    vertical-align: bottom;
    width: 100%;
}
ul, ol {
    list-style: none;
}
#sidebar {
    overflow-y: auto;
}

@keyframes popup {
    from {
        opacity: 0;
        transform: scale(0.85) translate(-50%, -50%); /* Ajustez pour centrer dès le départ */
    }
    to {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%); /* Garantit que le modal reste centré */
    }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;IACT,sBAAsB;AAC1B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,sBAAsB;IACtB,WAAW;AACf;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,UAAU;QACV,4CAA4C,EAAE,uCAAuC;IACzF;IACA;QACI,UAAU;QACV,yCAAyC,EAAE,uCAAuC;IACtF;AACJ","sourcesContent":["* {\n    padding: 0;\n    margin: 0;\n    box-sizing: border-box;\n}\nhtml {\n    scroll-behavior: smooth;\n}\n\na {\n    text-decoration: none;\n}\nimg {\n    vertical-align: bottom;\n    width: 100%;\n}\nul, ol {\n    list-style: none;\n}\n#sidebar {\n    overflow-y: auto;\n}\n\n@keyframes popup {\n    from {\n        opacity: 0;\n        transform: scale(0.85) translate(-50%, -50%); /* Ajustez pour centrer dès le départ */\n    }\n    to {\n        opacity: 1;\n        transform: scale(1) translate(-50%, -50%); /* Garantit que le modal reste centré */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
