import api from "../axiosInstance";
import {getUserRole, logout} from "../../utils/authUtils";

async function editAnnonce(editform, id) {
    try {
        const role = getUserRole();
        let url = '';

        if (role === 'super-admin' || role === 'all_pays') {
            url = `/annonce/update/${id}`;
        } else {
            url = `/annonce/update/${role}/${id}`;
        }

        const response = await api.put(url, editform);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Unauthorized access
            logout()
        }
        console.log(error);
        if (error.response && error.response.data && error.response.data.errors) {
            const { errors } = error.response.data;
            return { errors };
        } else {
            console.error('error:', error.message);
            throw error;
        }
    }
}

export { editAnnonce };
