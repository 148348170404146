import React, { useState } from 'react';
import isRole, { getUserName, logout } from "../utils/authUtils"; // Importez isRole depuis authUtils.js
import CustomModal from "../Commons/Component/CustomModal";
import { Link } from "react-router-dom";

const userData = getUserName();

function Sidebar({ children }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const openModalOnClick = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
    };

    const userHasRole = () => {
        const allowedRoles = ['super-admin', 'admin']; // Les rôles autorisés à afficher le lien "User"
        return isRole(allowedRoles); // Utilisez la fonction isRole de authUtils.js pour vérifier le rôle de l'utilisateur
    };

    return (
        <div className="font-poppins antialiased min-h-screen flex" id="sidebar">
            {/* Toggle Button for smaller screens */}
            <button
                onClick={toggleSidebar}
                className="p-2 border-2 bg-white rounded-md border-gray-200 shadow-lg text-gray-500 focus:bg-teal-500 focus:outline-none focus:text-white absolute top-0 left-0 m-4 sm:hidden"
            >
                {/* Icon changes based on the sidebar state */}
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
            </button>

            {/* Sidebar */}
            <div
                className={`bg-white shadow-xl px-3 py-4 w-3/10 space-y-6 absolute inset-y-0 left-0 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} sm:relative sm:translate-x-0 transition duration-200 ease-in-out`}
            >
                {/* Close Button for smaller screens */}
                <button
                    onClick={toggleSidebar}
                    className="p-2 border-2 bg-white rounded-md border-gray-200 shadow-lg text-gray-500 focus:bg-teal-500 focus:outline-none focus:text-white absolute top-0 right-0 m-4 sm:hidden"
                >
                    {/* Icon changes based on the sidebar state */}
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>

                {/* Sidebar content goes here */}
                <div className="text-center">
                    <img  src={decodeURIComponent(userData.image)}  alt="Profile" className="w-20 h-20 rounded-full mx-auto" />
                    <h1 className="text-xl font-semibold"><span className="text-[#129BC9]">TD</span> monitor screen</h1>
                    <p>{userData.firstName} {userData.lastName}</p>
                </div>
                <div className="space-y-2">
                    <Link to="/campagne" className="block text-sm px-2 py-2 text-gray-700 hover:bg-gray-200 rounded">Campagne</Link>
                    <Link to="/annonce" className="block text-sm px-2 py-2 text-gray-700 hover:bg-gray-200 rounded">Annonce</Link>
                    {userHasRole()  && (
                        <Link to="/user" className="block text-sm px-2 py-2 text-gray-700 hover:bg-gray-200 rounded">User</Link>
                    )}
                    <a href="#" className="block text-sm px-2 py-2 text-gray-700 hover:bg-gray-200 rounded" onClick={handleLogout}>Logout</a>
                    <a href="#" className="block text-sm px-2 py-2 text-gray-700 bg-gray-200 rounded" onClick={openModalOnClick}>See your links</a>
                </div>
            </div>

            <div className="flex-1 p-4 max-w-[80%] mx-auto">
                {children}
            </div>

            {/* Custom Modal */}
            <CustomModal isOpen={isModalOpen} onClose={toggleModal}>
                <h2> Your Link </h2>
                <p>https://slide.tddays.fr?country={userData.country}</p>
            </CustomModal>
        </div>
    );
}

export default Sidebar;
