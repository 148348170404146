import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../ui/Input';
import ApiSuccess from '../../Error-succes/Api-succes';
import { addCampagne } from "../../../Service/Campagne/CampagneAdd";
import { allAmByCountry } from "../../../Service/UserbyRole/GetAmbyCountry";
import { allSaleByCountry } from "../../../Service/UserbyRole/GetSalebyCountry";
import { getCountryId } from "../../../utils/authUtils"; // Import the getCountryId function

const addCampagneSchema = yup.object({
    client: yup.string().min(2, 'The client must contain at least 2 characters').required('The client is required'),
    typeProgramme: yup.string().min(2, 'The program type must contain at least 2 characters').required('The program type is required'),
    signature: yup.date().required('The signature date is required'),
    saleId: yup.number().required('The sale is required'),
    accountManagerId: yup.number().required('The account manager is required'),
    image: yup.string().url().required('The image is required'),
    live: yup.boolean(),
    status: yup.number(),
    categorieClient: yup.string(),
    accountManagerFilsId: yup.mixed().nullable(),
    countryId: yup.number().required('The country is required')
});

const AddCampagne = () => {
    const [addCampagneErrors, setAddCampagneErrors] = useState([]);
    const [registrationSuccess, setRegistrationSuccess] = useState("");
    const [AllAmByCountryOption, setAllAmByCountry] = useState([]);
    const [AllSaleByCountryOption, setAllSaleByCountry] = useState([]);

    const methods = useForm({
        resolver: yupResolver(addCampagneSchema),
    });

    const { handleSubmit, formState: { errors }, setValue, getValues } = methods;

    const handleSaveClick = async () => {
        try {
            const data = getValues(); // Get form data

            // Récupérer le country_id depuis localStorage
            let country_id = getCountryId();
            if (country_id) {
                country_id = Number(country_id);
                if (isNaN(country_id)) {
                    console.error("Invalid country_id:", country_id);
                    return; // Arrêter l'exécution si country_id est invalide
                }
                data.countryId = country_id;
            } else {
                console.error("No country_id found in localStorage");
                return; // Arrêter l'exécution si aucun country_id n'est trouvé
            }

            const addData = {
                client: data.client,
                type_programme: data.typeProgramme,
                signature: data.signature,
                sale_id: data.saleId,
                account_manager_id: data.accountManagerId,
                date_end: data.date_end,
                image: data.image,
                live: data.live,
                account_manager_fils_id: data.accountManagerFilsId,
                country_id: data.countryId
            };

            const isValid = await methods.trigger();

            if (isValid) {
                const response = await addCampagne(addData);
                setRegistrationSuccess("Campaign successfully added!");
                setAddCampagneErrors([]);

                // Recharger la page après un court délai pour que l'utilisateur voie le message de succès
                setTimeout(() => {
                    window.location.reload();
                }, 100); // 100 ms avant le rechargement
            }
        } catch (error) {
            console.error('Error adding campaign:', error);
            if (error.response && error.response.data && error.response.data.errors) {
                setAddCampagneErrors(error.response.data.errors);
            } else {
                setAddCampagneErrors([{ message: error.message }]);
            }
        }
    };

    const type_programme = [
        { value: "affiliation", label: "Affiliation" },
        { value: "Campaign", label: "Campaign" },
        { value: "Metapic", label: "Metapic" },
        { value: "Content to commerce", label: "Content to Commerce" },
        { value: "PVN", label: "PVN" },
    ];

    useEffect(() => {
        const loadData = async () => {
            try {
                const allAmByCountryData = await allAmByCountry();
                const formattedAmData = allAmByCountryData.map(accountManager => ({
                    value: accountManager.id.toString(),
                    label: `${accountManager.firstName} ${accountManager.lastName}`
                }));
                setAllAmByCountry(formattedAmData);

                const allSaleByCountryData = await allSaleByCountry();
                const formattedSaleData = allSaleByCountryData.map(sale => ({
                    value: sale.id.toString(),
                    label: `${sale.firstName} ${sale.lastName}`
                }));
                setAllSaleByCountry(formattedSaleData);

                // Set default values after loading options
                if (allAmByCountryData.length > 0) {
                    setValue("accountManagerId", allAmByCountryData[0].id.toString());
                }
                if (allSaleByCountryData.length > 0) {
                    setValue("saleId", allSaleByCountryData[0].id.toString());
                }
                if (type_programme.length > 0) {
                    setValue("typeProgramme", type_programme[0].value);
                }

                // Set countryId from localStorage
                const country_id = getCountryId();
                if (country_id) {
                    setValue("countryId", country_id);
                }

            } catch (error) {
                console.error("Error loading data:", error);
            }
        };

        loadData();
    }, [setValue]);

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSaveClick)}>
                    {registrationSuccess && <ApiSuccess message={registrationSuccess} />}
                    {addCampagneErrors.map((error, index) => (
                        <div className={"text-red-500"} key={index}>{error.message}</div>
                    ))}
                    <Input {...methods.register("client")} placeholder="Client" color="blue" size="lg" label="Client" />
                    <p>{errors.client?.message}</p>

                    <Input {...methods.register("signature")} type="date" color="blue" size="lg" label="Signature" />
                    {errors.signature?.message && (<p className="text-red-500">{errors.signature?.message}</p>)}

                    <Input {...methods.register("date_end")} type="date" color="blue" size="lg" label="Date End" />
                    {errors.date_end?.message && (
                        <p className="text-red-500">{errors.date_end?.message}</p>
                    )}

                    <Input {...methods.register("typeProgramme")} type="select" options={type_programme} label="Program Type" />
                    {errors.typeProgramme?.message && (
                        <p className="text-red-500">{errors.typeProgramme?.message}</p>
                    )}
                    <Input {...methods.register("image")} placeholder="Image" color="blue" size="lg" label="Image" />
                    <p>{errors.image?.message}</p>

                    <Input {...methods.register("live")} type="select" options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]} color="blue" size="lg" label="Live" />

                    <Input {...methods.register("accountManagerId")} type="select" options={AllAmByCountryOption} label="Account Manager" />
                    <p>{errors.accountManagerId?.message}</p>

                    <Input {...methods.register("accountManagerFilsId")} type="select" options={AllAmByCountryOption} label="Account Manager Children" />
                    <p>{errors.accountManagerFilsId?.message}</p>

                    <Input {...methods.register("saleId")} type="select" options={AllSaleByCountryOption} label="Sale" />
                    <p>{errors.saleId?.message}</p>

                    {/* No need for a country select input as it's now directly from localStorage */}
                    <p>{errors.countryId?.message}</p>

                    <button type="button" onClick={handleSaveClick} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"> Save Changes </button>
                </form>
            </FormProvider>
        </div>
    );
}

export default AddCampagne;
