import api from "../axiosInstance";
import {getUserRole, logout} from "../../utils/authUtils";
import axios from "axios";

const role = getUserRole();

async function allSaleByCountry(redirectTo = null) {
    try {
        let url = '/AllSaleByCountry';
        const response = await api.get(url);
        return response.data; // Si vous attendez des données en réponse
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Unauthorized access
            logout()
        }
        if (error.response && error.response.data && error.response.data.errors) {
            // Si des erreurs sont renvoyées par le serveur
            const { errors } = error.response.data;
            return { errors };
        } else {
            // Si une erreur inattendue s'est produite
            console.error('error:', error.message);
            throw error;
        }
    }
}

export { allSaleByCountry };
