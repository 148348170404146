import React from 'react';
import './CustomCard.css'; // Assurez-vous de créer le fichier CustomCard.css pour définir les styles

const CustomView = ({ annonce }) => {
    return (
        <div className="custom-card-container">
            <div className="custom-card">
                <div className="card-content">
                    <h2 className="title">{annonce.title}</h2>
                    <p className="description">{annonce.presentation}</p>
                    <img src={decodeURIComponent(annonce.image)} alt="Annonce" className="image" />
                    <div className="details">
                        <p><span className="label">ID:</span> {annonce.id}</p>
                        <p><span className="label">Date:</span> {annonce.date}</p>
                        <p><span className="label">Catégorie:</span> {annonce.categorie}</p>
                        <p><span className="label">Status:</span> {annonce.status === 1 ? 'Draft' : 'Published'}</p>
                        {/* Ajoutez d'autres détails de l'annonce ici */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomView;




