// authUtils.js


// Fonction pour vérifier si l'utilisateur est connecté
export const isLoggedIn = () => {
    // Récupérer le token JWT du localStorage
    const token = localStorage.getItem('token');

    // Vérifier si le token est présent et non vide
    if (token) {
        // Token présent, l'utilisateur est connecté
        return true;
    } else {
        // Token non présent, l'utilisateur n'est pas connecté
        return false;
    }
};

// Fonction isRole
const isRole = (allowedRoles) => {
    const userData = localStorage.getItem('userData');

    // Vérifiez d'abord si userData est null
    if (!userData) {
        return false;
    }

    // Ensuite, parsez userData et accédez à la propriété role
    const userRoles = JSON.parse(userData).role;
    console.log(userRoles);

    // Continuez comme avant avec la vérification des rôles
    if (!userRoles || !Array.isArray(userRoles) || userRoles.length === 0) {
        return false;
    }

    return userRoles.some(role => allowedRoles.includes(role));
};

export const getUserRole = () => {
    const userData = localStorage.getItem('userData');
    if (!userData) {
        return 'No role'; // ou une autre valeur par défaut
    }
    const { role } = JSON.parse(userData);

    if (Array.isArray(role)) {
        return role.join(', '); // Si plusieurs rôles, les combine
    }
    return role; // Supposons qu'il s'agit d'une chaîne simple
};

export default isRole;

export const getUserName = () => {
    const userData = localStorage.getItem('userData');
    if (!userData) {
        return {}; // ou une autre valeur par défaut
    }
    const decodedUserData = JSON.parse(userData); // ou jwtDecode(userData) si userData est un JWT token

    return decodedUserData;
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    window.location.assign('/login'); // Redirection vers la page de connexion
};

export const getCountryId = () => {
    // Récupère les données utilisateur depuis le localStorage
    const userData = localStorage.getItem('userData');

    // Si userData n'existe pas, renvoie null
    if (!userData) {
        return null; // Retourne null ou une valeur par défaut appropriée si aucune donnée utilisateur n'est trouvée
    }

    // Parse les données JSON pour obtenir un objet
    const parsedUserData = JSON.parse(userData);

    // Récupère le 'country_id' à partir de l'objet JSON
    const countryId = parsedUserData.country_id;

    // Convertit la valeur en un entier
    const parsedCountryId = parseInt(countryId, 10);

    // Si la conversion échoue, retourne null, sinon retourne l'entier
    return isNaN(parsedCountryId) ? null : parsedCountryId;
};
