import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../ui/Input';
import ApiSuccess from '../../Error-succes/Api-succes';
import { editCampagne } from '../../../Service/Campagne/CampagneEdit';
import { allAmByCountry } from "../../../Service/UserbyRole/GetAmbyCountry";
import { allSaleByCountry } from "../../../Service/UserbyRole/GetSalebyCountry";
import { getCountryId } from "../../../utils/authUtils"; // Import the getCountryId function
import { format } from 'date-fns';

// Validation schema for editing campaign
const editCampagneSchema = yup.object({
    client: yup.string().min(2, 'The client must contain at least 2 characters').required('The client is required'),
    typeProgramme: yup.string().min(2, 'The program type must contain at least 2 characters').required('The program type is required'),
    signatures: yup.date().required('The signature date is required'),
    saleId: yup.number().required('The sale is required'),
    accountManagerId: yup.number().required('The account manager is required'),
    image: yup.string().url().required('The image is required'),
    live: yup.boolean(),
    status: yup.number(),
    categorieClient: yup.string(),
    accountManagerFilsId: yup
        .mixed()
        .transform((value) => (value === "" ? null : value))
        .nullable(), // Allow null values for optional field
    countryId: yup.number().required('The country is required')
});

const EditCampagne = ({ campagne, dataErrors }) => {
    const [editCampagneErrors, setEditCampagneErrors] = useState([]);
    const [registrationSuccess, setRegistrationSuccess] = useState("");

    const methods = useForm({
        resolver: yupResolver(editCampagneSchema),
        defaultValues: campagne // Use campaign data as default values
    });

    const { handleSubmit, formState: { errors }, setValue, getValues } = methods;

    const handleSaveClick = async () => {
        try {
            const data = getValues(); // Get form data
    
            if (!campagne.signature || !campagne.dateEnd) {
                console.error("Signature or date_end properties are empty or undefined.");
                return;
            }
    
            // Format dates
            const signature = format(new Date(data.signature), 'yyyy-MM-dd');
            const dateEnd = format(new Date(data.dateEnd), 'yyyy-MM-dd');
    
            // Récupérer le country_id depuis le localStorage
            let country_id = getCountryId();
            if (country_id) {
                country_id = Number(country_id);
                if (isNaN(country_id)) {
                    console.error("Invalid country_id:", country_id);
                    return; // Arrêter l'exécution si country_id est invalide
                }
                data.countryId = country_id;
            } else {
                console.error("No country_id found in localStorage");
                return; // Arrêter l'exécution si aucun country_id n'est trouvé
            }

            // Create the edited data object
            const editedData = {
                client: data.client,
                type_programme: data.typeProgramme,
                signature: signature,
                sale_id: data.saleId,
                account_manager_id: data.accountManagerId,
                date_end: dateEnd,
                image: data.image,
                live: data.live,
                account_manager_fils_id: data.accountManagerFilsId,
                country_id: data.countryId
            };
    
            const isValid = await methods.trigger();
            if (isValid) {
                // Call the edit campaign function with modified data
                const response = await editCampagne(editedData, campagne.id);
                setRegistrationSuccess("Modification enregistrée !");
                setEditCampagneErrors([]);
    
                // Reload the page after a short delay to show the success message
                setTimeout(() => {
                    window.location.reload();
                }, 1500); // 1500 ms (1.5 seconds) before reloading
            }
    
        } catch (error) {
            console.error('Error editing campaign:', error);
            if (error.response && error.response.data && error.response.data.errors) {
                setEditCampagneErrors(error.response.data.errors);
            } else {
                setEditCampagneErrors([{ message: error.message }]);
            }
        }
    };

    useEffect(() => {
        if (campagne) {
            Object.entries(campagne).forEach(([key, value]) => {
                // Ensure the image URL is decoded before setting it in the form
                if (key === 'image' && value) {
                    setValue(key, decodeURIComponent(value));
                } else {
                    setValue(key, value);
                }
            });
        }

        // Set the countryId from localStorage
        const country_id = getCountryId();
        if (country_id) {
            setValue("countryId", country_id);
        }
    }, [campagne, setValue]);

    const [AllAmBycountryOption, setAllAmByCountry] = useState([]);
    const [AllSaleBycountryOption, setAllSaleByCountry] = useState([]);
    const campagneState = [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
    ];

    const type_programme = [
        { value: "affiliation", label: "affiliation" },
        { value: "Campagne", label: "Campagne" },
        { value: "Metapic", label: "Metapic" },
        { value: "Content to commerce ", label: "Content to commerce " },
        { value: "PVN ", label: "PVN " },
    ];

    const loadAmByCountry = async () => {
        try {
            const allAmByCountryData = await allAmByCountry();

            if (allAmByCountryData !== null && allAmByCountryData !== undefined) {
                if (Array.isArray(allAmByCountryData)) {
                    const formattedData = allAmByCountryData.map(accountManager => ({
                        value: accountManager.id.toString(),
                        label: `${accountManager.firstName} ${accountManager.lastName}`
                    }));
                    setAllAmByCountry(formattedData);
                } else {
                    console.error("Unexpected data type received:", typeof allAmByCountryData);
                }
            } else {
                console.error("No data received from allAmByCountry");
            }
        } catch (error) {
            console.error("Error loading country options:", error);
        }
    };

    useEffect(() => {
        loadAmByCountry();
    }, []);

    useEffect(() => {
        const loadSaleByCountry = async () => {
            try {
                const allSaleByCountryData = await allSaleByCountry();

                if (allSaleByCountryData !== undefined) {
                    if (Array.isArray(allSaleByCountryData)) {
                        const formattedData = allSaleByCountryData.map(sale => ({
                            value: sale.id.toString(),
                            label: `${sale.firstName} ${sale.lastName}`
                        }));
                        setAllSaleByCountry(formattedData);
                    } else {
                        console.error("Unexpected data type received:", typeof allSaleByCountryData);
                    }
                } else {
                    console.error("No data received from allSaleByCountry");
                }
            } catch (error) {
                console.error("Error loading sale options:", error);
            }
        };

        loadSaleByCountry();
    }, []);

    const formatDate = (date) => {
        if (!date) return ''; // Return an empty string if date is empty
        const formattedDate = new Date(date).toISOString().split('T')[0];
        return formattedDate;
    };

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSaveClick)}>
                    {registrationSuccess && <ApiSuccess message={registrationSuccess} />}
                    {editCampagneErrors.map((error, index) => (
                        <div className="text-red-500" key={index}>{error.message}</div>
                    ))}
                    {dataErrors && dataErrors.length > 0 && (
                        <div className="error-container">
                            {dataErrors.map((error, index) => (
                                <p key={index} className="error-message">{error.message}</p>
                            ))}
                        </div>
                    )}

                    <Input {...methods.register("client")} defaultValue={campagne?.client || ''} placeholder="Client" color="blue" size="lg" label="Client" />
                    <p>{errors.client?.message}</p>

                    <Input {...methods.register("signatures")} type="date" color="blue" size="lg" label="Signatures" defaultValue={formatDate(campagne?.signature) || ''}/>
                    {errors.signature?.message && (<p className="text-red-500">{errors.signature?.message}</p>)}

                    <Input {...methods.register("date_end")} type="date" color="blue" size="lg" label="Date End" defaultValue={formatDate(campagne?.dateEnd) || ''}/>
                    {errors.date_end?.message && (
                        <p className="text-red-500">{errors.date_end?.message}</p>
                    )}

                    <Input {...methods.register("typeProgramme")} type="select" options={type_programme} label="Type de programme" defaultValue={type_programme[0]?.value} />
                    
                    <Input
                        {...methods.register("image")}
                        placeholder="Image"
                        color="blue"
                        size="lg"
                        label="Image"
                    />
                    <p>{errors.image?.message}</p>

                    <Input {...methods.register("live")} type="select" options={campagneState} defaultValue={campagne?.live || ''} color="blue" size="lg" label="live" />

                    <Input {...methods.register("accountManagerId")} type="select" options={AllAmBycountryOption} label="AccountManager" defaultValue={campagne?.account_manager || ''} />
                    <p>{errors.accountManagerId?.message}</p>

                    <Input {...methods.register("accountManagerFilsId")} type="select" options={AllAmBycountryOption} label="AccountManagerChildren" defaultValue={campagne?.account_managerfils || ''} />
                    <p>{errors.accountManagerFilsId?.message}</p>

                    <Input {...methods.register("saleId")} type="select" options={AllSaleBycountryOption} label="Sale" name="Sale" defaultValue={campagne?.sale || ''} />
                    <p>{errors.saleId?.message}</p>

                    {/* No need for a country select input as it's now directly from localStorage */}
                    <p>{errors.countryId?.message}</p>

                    <button type="button" onClick={handleSaveClick} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">Save changes</button>
                </form>
            </FormProvider>
        </div>
    );
}

export default EditCampagne;
