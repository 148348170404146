import api from "../axiosInstance";
import {getUserRole, logout} from "../../utils/authUtils";

const role = getUserRole()

async function addAnnonce(addform) {

// Charger les variables d'environnement à partir du fichier .env
    try {
        let url = `/annonce/create`;
        console.log(url);
        const response = await api.post(url, addform); // Passez les données du formulaire en deuxième argument de la méthode put
        console.log(response);
        console.log(response);
        return response.data; // Si vous attendez des données en réponse
    } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 401) {
            // Unauthorized access
            console.log("Unauthorized access");
            console.log("Deconnexion: Unauthorized access");
            logout()
        }
        if (error.response && error.response.data && error.response.data.errors) {
            // Si des erreurs sont renvoyées par le serveur
            const { errors } = error.response.data;
            return { errors };
        } else {
            // Si une erreur inattendue s'est produite
            console.error('error:', error.message);
            throw error;
        }
    }
}

export {addAnnonce }
