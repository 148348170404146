import React, { useEffect, useState } from "react";
import Input from "../../Commons/Component/ui/Input";
import Background from "../../Commons/Component/ui/body-color-auth";
import { registerUser } from "../../Service/Auth/Register/registerService";
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import registerValidationSchema from "../../validation/registerSchema";
import { allCountry } from "../../Service/Country/allCountry";
import ApiError from "../../Commons/Error-succes/Api-Error";
import ApiSuccess from "../../Commons/Error-succes/Api-succes";
import { format } from 'date-fns';
import { Link, useNavigate } from "react-router-dom"; // Importez useNavigate


export default function Register() {
    const methods = useForm({
        resolver: yupResolver(registerValidationSchema),
    });
    const navigate = useNavigate(); // Utilisez useNavigate

    const [countryOptions, setCountryOptions] = useState([]);
    const [registrationErrors, setRegistrationErrors] = useState([]);
    const [registrationSuccess, setRegistrationSuccess] = useState("");
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);


    useEffect(() => {
        const loadCountryOptions = async () => {
            try {
                const countries = await allCountry();
                setCountryOptions(countries.map(country => ({ value: country.id.toString(), label: country.name })));
            } catch (error) {
                console.error("Error loading country options:", error);
            }
        };

        loadCountryOptions();
    }, []);

    const onSubmit = async (data) => {
        console.log(data); // Afficher les données soumises pour le débogage

        let submissionData = { ...data };

        // Formater les dates en 'YYYY-MM-DD' sans conversion en UTC
        if (data.arrival_date) {
            submissionData.arrival_date = format(new Date(data.arrival_date), 'yyyy-MM-dd');
        }
        if (data.birth_date) {
            submissionData.birth_date = format(new Date(data.birth_date), 'yyyy-MM-dd');
        }

        try {
            const response = await registerUser(submissionData);
            // ...Votre code existant pour le succès

            // Gérer à la fois error et errors dans la réponse
            if (response.error) {
                // Stocker les erreurs singulières dans un tableau d'objet avec un champ `message`
                setRegistrationErrors([{ message: response.error }]);
            } else if (response.errors) {
                // Assumer que response.errors est déjà un tableau d'objets avec un champ `message`
                setRegistrationErrors(response.errors);
            } else {
                // Si tout va bien
                setRegistrationErrors([]);
                setRegistrationSuccess("Registration successful!");
                navigate("/login"); // Rediriger après le succès de l'inscription

            }
        } catch (error) {
            console.error("Registration error:", error);
            setRegistrationErrors([{ message: 'Unexpected error during registration' }]);
        }
    };



    const teamOptions = [
        { value: "AM", label: "AM" },
        { value: "Sale", label: "Sale" },
        { value: "Tech", label: "Tech" },
        { value: "Publisher", label: "Publisher" },
    ];


    useEffect(() => {
        const userData = localStorage.getItem("token");
        if (userData) {
            window.location.assign('/annonce'); // Redirection vers la page de connexion
        }
    });


    return (
        <Background>
            <div className="flex w-3/4 place-content-center justify-center items-center bg-white" style={{ height: '90vh' }}>
                <div className="min-h-screen flex justify-center items-center ">
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4" style={{ maxWidth: "800px", width: "100%" }}>
                            <img src="/img/logo-td-black.png" alt="Logo" className="w-1/5 mx-auto block mb-8" />

                            {registrationErrors && registrationErrors.length > 0 && (
                                <ApiError error={registrationErrors.map(error => error.message).join(', ')} />
                            )}
                            {registrationSuccess && <ApiSuccess message={registrationSuccess} />}

                            <Input type="text" placeholder="Last Name" label="Last Name" name="last_name" />
                            <Input type="text" placeholder="First Name" label="First Name" name="first_name" />
                            <Input type="email" placeholder="Email" label="Email" name="email" />
                            <Input type="password" placeholder="Password" label="Password" name="password" />
                            <Input type="date" placeholder="Arrival Date" label="Arrival Date" name="arrival_date" />
                            <Input type="date" placeholder="Birth Date" label="Birth Date" name="birth_date" />
                            <Input type="text" placeholder="Image URL" label="Image URL" name="image" />
                            <Input type="select" options={countryOptions} label="Country" name="country_id" />
                            <Input type="select" options={teamOptions} label="Team" name="team" />

                            <div className="flex justify-center">
                                <button type="submit" className="bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Register</button>
                            </div>
                            <Link to="/Login" className="text-center underline underline-offset-1 flex justify-center">Login</Link>
                        </form>
                    </FormProvider>
                </div>

            </div>
        </Background>
    );
}
