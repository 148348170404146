import React, { useEffect, useState } from 'react';
import CustomModal from "../../Commons/Component/CustomModal";
import EditCampagne from "../../Commons/Component/Campagne/EditCampagne";
import DeleteCampagneButton from "../../Commons/Component/Campagne/DeleteCampagne";
import AddCampagne from "../../Commons/Component/Campagne/AddCampagne";
import { getUserRole } from "../../utils/authUtils";
import { getUserName } from "../../utils/authUtils";
import { allCampagne } from "../../Service/Campagne/CampagneGet";
import Pagination from "../../Commons/Component/Pagination/paginationTableau";
import CustomCampagneView from "../../Commons/Component/Cards/CustomCampagneView";
import CountryFilter from "../../Commons/Component/Filtre/CountryFilter";

const userData = getUserName();

const userRole = getUserRole();

function CampagnePage() {
    const [campagne, setCampagne] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCampagne, setSelectCampagne] = useState(null);
    const [modalAction, setModalAction] = useState('');
    const [dataErrors, setDataErrors] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [campagnePerPage] = useState(4); // Nombre d'éléments par page
    const [selectedCountry, setSelectedCountry] = useState('');

    useEffect(() => {
        allCampagne()
            .then(data => {
                console.log(data);
                if (data.errors) {
                    setError(data.errors);
                } else {
                    setCampagne(data);
                }
            })
            .catch(err => {
                setError(err.message);
            });
    }, []);

    const openModal = (id, action) => {
        const selectedCampagne = campagne.find((a) => a.id === id);
        if (selectedCampagne) {
            setSelectCampagne(selectedCampagne);
            setModalAction(action);
            setIsModalOpen(true);
        }
    };

    const openAddModal = () => {
        setIsModalOpen(true);
        setSelectCampagne(null);
        setModalAction('add');
    };

    if (error) {
        return <div>Erreur : {error}</div>;
    }

    const indexOfLastCampagne = currentPage * campagnePerPage;
    const indexOfFirstCampagne = indexOfLastCampagne - campagnePerPage;

    let filteredCampagne = campagne;
    if (selectedCountry) {
        filteredCampagne = filteredCampagne.filter(campagne => campagne.country.name === selectedCountry);
    }

    const currentCampagne = filteredCampagne.slice(indexOfFirstCampagne, indexOfLastCampagne);

    const totalPages = Math.ceil(filteredCampagne.length / campagnePerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className="p-4">
                <h1 className="text-2xl font-bold mb-4">Campagne</h1>
                <div className="flex justify-end mb-4">
                    <button onClick={openAddModal} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:bg-blue-600">
                        Add Campagne
                    </button>
                </div>
                <CountryFilter
                    countries={[...new Set(campagne.map(c => c.country.name))]}
                    onCountryChange={(selectedCountry) => setSelectedCountry(selectedCountry)}
                />
                <div className="overflow-x-auto">
                    <table className="table-auto w-full whitespace-nowrap text-left divide-y divide-gray-200">
                        <thead>
                        <tr>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">ID</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Sale</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Image</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Signature</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Account Manager</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Client</th>
                            {userRole !== 'user' && userRole !== 'admin' && (
                                <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Country</th>
                            )}
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Account Manager Children</th>
                            <th className="font-medium text-gray-700 tracking-wider px-4 py-2">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {currentCampagne.map((campagne, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="px-4 py-2">{campagne.id || '-'}</td>
                                <td className="px-4 py-2">{campagne.sale.firstName + ' ' + campagne.sale.lastName || '-'}</td>
                                <td className="px-4 py-2">
                                    {decodeURIComponent(campagne.image) ? (
                                        <img src={decodeURIComponent(campagne.image)} alt="campagne" className="w-20 h-20 object-cover" />
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td className="px-4 py-2">
                                    {campagne.signature ? new Intl.DateTimeFormat('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: '2-digit'
                                    }).format(new Date(campagne.signature)) : '-'}
                                </td>
                                <td className="px-4 py-2">{campagne.accountManager ? campagne.accountManager.firstName + ' ' + campagne.accountManager.lastName : '-'}</td>
                                <td className="px-4 py-2">{campagne.client || '-'}</td>
                                {userRole !== 'user' && userRole !== 'admin' && (
                                    <td className="px-4 py-2">{campagne.country ? campagne.country.name : '-'}</td>
                                )}
                                <td className="px-4 py-2">{campagne.accountManagerFils ? campagne.accountManagerFils.firstName + ' ' + campagne.accountManagerFils.lastName : '-'}</td>
                                <td className="px-4 py-2">
                                    <div className="flex gap-2 justify-center items-center">
                                        <button onClick={() => openModal(campagne.id, 'view')} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:bg-blue-600">
                                            View
                                        </button>
                                        <button onClick={() => openModal(campagne.id, 'edit')} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:bg-green-600">
                                            Edit
                                        </button>
                                        <button onClick={() => openModal(campagne.id, 'delete')} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:bg-red-600">
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {totalPages > 1 && (
                    <Pagination usersPerPage={campagnePerPage} totalUsers={filteredCampagne.length} paginate={paginate} />
                )}
            </div>
            <CustomModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={selectedCampagne ? `Campagne: ${selectedCampagne.client}` : ''}
            >
                {modalAction === 'edit' && selectedCampagne && (
                    <EditCampagne campagne={selectedCampagne} dataErrors={dataErrors} />
                )}
                {modalAction === 'add' && <AddCampagne />}
                {modalAction === 'view' && selectedCampagne && <CustomCampagneView campagne={selectedCampagne} dataErrors={dataErrors} />}
                {modalAction === 'delete' && selectedCampagne && <DeleteCampagneButton id={selectedCampagne.id} />}
            </CustomModal>
        </>
    );
}

export default CampagnePage;

