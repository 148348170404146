import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '../ui/Input';
import { addAnnonce } from "../../../Service/Annonce/AnnonceAdd";
import ApiSuccess from "../../Error-succes/Api-succes";
import { getCountryId } from "../../../utils/authUtils"; // Import the getCountryId function

// Définition du schéma de validation avec yup
const validationSchema = yup.object({
    presentation: yup.string().min(10, 'La présentation doit contenir au moins 10 caractères').required('La présentation est obligatoire'),
    image: yup.string().url('Doit être une URL valide').required('L\'URL de l\'image est obligatoire'),
    date: yup.date().required('La date est obligatoire'),
    categorie: yup.string().required('La catégorie est obligatoire'),
    title: yup.string().min(2, 'Le titre doit contenir au moins 2 caractères').required('Le titre est obligatoire'),
    id_employe: yup.number().positive('L\'ID employé doit être un nombre positif').required('L\'ID employé est obligatoire'),
    live: yup.boolean(),
    date_end: yup.date().nullable().optional(),
    status: yup.number().positive('Le statut doit être un nombre positif').required('Le statut est obligatoire'),
});

const AddAnnonce = () => {
    const [addAnnonceErrors, setAddAnnonceErrors] = useState([]);
    const [registrationSuccess, setRegistrationSuccess] = useState("");

    const methods = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = methods;

    // Récupération de country_id depuis le localStorage
    let country_id = getCountryId();
    console.log(country_id);

    const handleSaveClick = async () => {
        try {
            const data = getValues();
            data.status = 1;

            // Validation et conversion de country_id
            if (country_id) {
                country_id = Number(country_id); // Convert to number using Number()
                if (isNaN(country_id)) {
                    console.error("Invalid country_id:", country_id);
                    return; // Stop execution if country_id is invalid
                }
                console.log("Country ID:", country_id); // Log the country_id as a number
                data.country_id = country_id;
            } else {
                console.error("No country_id found in localStorage");
                return; // Stop execution if no country_id is found
            }

            const response = await addAnnonce(data);
            if (response && response.errors) {
                setAddAnnonceErrors(response.errors);
            }
            if (response && !response.errors) {
                setRegistrationSuccess("Annonce ajoutée avec succès!");
                setAddAnnonceErrors([]);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error adding annonce:', error);
        }
    };

    const categorie = [
        { value: "general", label: "general" },
        { value: "social", label: "social" },
        { value: "media", label: "media" },
        { value: "departure", label: "departure" },
    ];

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSaveClick)}>
                    {registrationSuccess && <ApiSuccess message={registrationSuccess} />}
                    {addAnnonceErrors.map((error, index) => (
                        <div className={"text-red-500"} key={index}>{error.message}</div>
                    ))}
                    <Input type="textarea" {...register("presentation")} placeholder="Presentation" color="blue" size="lg" label="Presentation" />
                    <p>{errors.presentation?.message}</p>

                    <Input {...register("image")} placeholder="URL de l'image" color="blue" size="lg" label="Image" />
                    <p>{errors.image?.message}</p>

                    <Input {...register("date")} type="date" color="blue" size="lg" label="Date" />
                    <p>{errors.date?.message}</p>

                    <Input {...register("categorie")} type="select" options={categorie} color="blue" size="lg" label="Categorie" defaultValue="general" />
                    <p>{errors.categorie?.message}</p>

                    <Input {...register("title")} placeholder="Title" color="blue" size="lg" label="Title" />
                    <p>{errors.title?.message}</p>

                    <Input
                        {...register("live")}
                        type="select"
                        options={[
                            { value: true, label: "Oui" },
                            { value: false, label: "Non" }
                        ]}
                        color="blue"
                        size="lg"
                        label="Live"
                    />

                    {/* Pas besoin d'un champ de sélection pour country_id, il est maintenant défini directement depuis le localStorage */}

                    <button type="submit" onClick={handleSaveClick} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"> Add Annonce </button>
                </form>
            </FormProvider>
        </div>
    );
}

export default AddAnnonce;
