// src/validations/registerValidationSchema.js
import * as yup from 'yup';

const registerValidationSchema = yup.object().shape({
    email: yup.string().email('Invalid email format').min(5, 'Email must be at least 5 characters long').required('Email is required'),
    last_name: yup.string().min(2, 'Last name must be at least 2 characters long').required('Last name is required'),
    first_name: yup.string().min(2, 'First name must be at least 2 characters long').required('First name is required'),
    password: yup.string().required('Password is required'),
    team: yup.string().min(1, 'Team must be at least 1 character long').required('Team is required'),
    arrival_date: yup.date().required('Arrival date is required'),
    birth_date: yup.date().required('Birth date is required'),
    image: yup.string().url('Invalid URL format').required('Image URL is required'),
    country_id: yup.number().positive('Country ID must be a positive number').required('Country ID is required'),
});

export default registerValidationSchema;
